import { Input } from "@/components/Inputs/Input"
import { Scrollable } from "@/components/Layouts/Scrollable"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { IfcHandler } from "../IfcHandler"
import { SpatialStructureTreeRow } from "./SpatialStructureTreeRow"

interface SpatialStructureTreeProps {
    ifcHandler: IfcHandler
}

export const SpatialStructureTree: React.FC<SpatialStructureTreeProps> = ({
    ifcHandler,
}) => {
    const [spatialStructure, setSpatialStructure] = useState<{
        [key: string]: { [key: string]: Set<number> }
    }>({})
    const [search, setSearch] = useState<string>("")
    const { t } = useTranslation()
    // useEffect(() => {
    //     setSpatialStructure(ifcHandler.getSpacialStructure())
    // }, [])
    return (
        <Scrollable height="250px" className="px-2 py-1 flex flex-col gap-4">
            <div className="flex items-center gap-2">
                <FontAwesomeIcon icon="search" className="text-secondary-100" />
                <Input
                    value={search}
                    type="text"
                    name={t("search")}
                    onChange={(e) => setSearch(e.target.value)}
                    placeholder={t("search") + "..."}
                    className="!m-0 w-full text-primary-300"
                />
            </div>

            <div className="w-[300px]  flex flex-col gap-1">
                {Object.keys(spatialStructure).map((key) => (
                    <SpatialStructureTreeRow
                        ifcHandler={ifcHandler}
                        key={key}
                        name={key}
                        value={spatialStructure[key]}
                        search={search}
                    />
                ))}
            </div>
        </Scrollable>
    )
}
