import ProjectPicturePlaceHolder from "@/assets/project-placeholder.png"
import { Project } from "@/models/Project"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import { Popup, POPUP_TYPES } from "../Menus/Popup"

interface ProjectCardProps {
    project: Project
}

export const ProjectCard: React.FC<ProjectCardProps> = ({ project }) => {
    const { t } = useTranslation()
    const descriptionRef = useRef<HTMLParagraphElement>(null)
    const [showDescription, setShowDescription] = useState<boolean>(false)
    const truncate = (str: string, n: number) => {
        return str.length > n ? str.slice(0, n - 1) + "..." : str
    }
    return (
        <div
            className={`w-[400px] sm:w-full lg:w-2/4 -mt-14 p-3 flex flex-col group`}
        >
            <Link
                to={`/dashboard/projects/${project.id}/details`}
                className={`flex-col w-full h-full`}
            >
                <div className="w-[150px] h-[150px] m-auto relative top-16 bg-secondary-100 p-4 rounded-full group-hover:bg-primary-100 transition  flex items-center justify-center">
                    <img
                        className={`object-cover rounded-full w-[110px] h-[110px]`}
                        src={
                            project.pictures[0]?.path ??
                            ProjectPicturePlaceHolder
                        }
                        alt=""
                    />
                </div>
                <div className="bg-secondary-100 rounded-lg shadow-lg h-[220px] sm:h-[260px] w-full group-hover:bg-primary-100 group-hover:text-secondary-100 transition">
                    <div className="flex justify-between w-full h-full p-3 pt-20">
                        <div className="flex flex-col h-full w-full">
                            <h1 className="text-2xl text-bold">
                                {project.name}
                            </h1>
                            <div>
                                <p
                                    className="text-xs"
                                    ref={descriptionRef}
                                    onMouseEnter={() =>
                                        setShowDescription(true)
                                    }
                                    onMouseLeave={() =>
                                        setShowDescription(false)
                                    }
                                >
                                    <span className="font-bold">
                                        {t("description")}:{" "}
                                    </span>
                                    {truncate(project.description, 200)}
                                </p>
                                <Popup
                                    isShown={
                                        showDescription &&
                                        project.description.length > 200
                                    }
                                    closePopup={() => setShowDescription(false)}
                                    type={POPUP_TYPES.HOVER}
                                    parentRef={descriptionRef}
                                    className="w-96 text-primary-300"
                                >
                                    <p>{project.description}</p>
                                </Popup>
                            </div>
                            <div className="flex justify-between items-center mt-auto text-xs flex-wrap">
                                <p>
                                    <FontAwesomeIcon icon="map-marker-alt" />{" "}
                                    {project.address}
                                </p>
                                <p>
                                    <FontAwesomeIcon icon="clock" />{" "}
                                    {t("intlDateTime", {
                                        val: project.createdAt,
                                        formatParams: {
                                            val: {
                                                year: "numeric",
                                                month: "short",
                                                day: "numeric",
                                            },
                                        },
                                    })}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </Link>
        </div>
    )
}
