import { BaseModel, BaseModelInterface } from "./BaseModel"
import { ProjectJsonInterface } from "./Project"
import { Task, TaskJsonInterface, TaskStatusDetailNameSpace } from "./Task"
import { User, UserInterface } from "./User"

export enum THUMBNAIL_SIZE {
    SMALL = "small",
    MEDIUM = "medium",
    LARGE = "large",
}

export interface FileJsonInterface extends BaseModelInterface {
    name: string
    path: string
    contentType: string
    size: number
    thumbnails: Record<THUMBNAIL_SIZE, string>
    uploadedBy: UserInterface
    project?: ProjectJsonInterface
    type?: ProjectFileTypeEnum
    task?: TaskJsonInterface
}

export class File extends BaseModel {
    public name: string
    public path: string
    public contentType: string
    public size: number
    public thumbnails: Record<THUMBNAIL_SIZE, string>
    public uploadedBy: User

    constructor(data?: FileJsonInterface) {
        super(data)
        this.name = data?.name ?? ""
        this.path = data?.path ?? ""
        this.contentType = data?.contentType ?? ""
        this.size = data?.size ?? 0
        this.thumbnails = data?.thumbnails ?? {
            [THUMBNAIL_SIZE.SMALL]: "",
            [THUMBNAIL_SIZE.MEDIUM]: "",
            [THUMBNAIL_SIZE.LARGE]: "",
        }
        this.uploadedBy = new User(data?.uploadedBy) ?? new User()
    }

    public toJson(): FileJsonInterface {
        return {
            ...super.toJson(),
            name: this.name,
            path: this.path,
            contentType: this.contentType,
            size: this.size,
            thumbnails: this.thumbnails,
            uploadedBy: this.uploadedBy.toJson(),
        }
    }
}

export enum ProjectFileTypeEnum {
    IFC = "ifc",
    PHOTO = "photo",
    PROJECT_PICTURE = "project_picture",
    PDF = "pdf",
    OTHER = "other",
}

export enum BIMFileStatus {
    PENDING = "pending",
    APPROVED = "approved",
    APPROVED_WITH_ISSUES = "approved_with_issues",
    REJECTED = "rejected",
}

export const taskStatusBimFileMapping = {
    [TaskStatusDetailNameSpace.NOT_STARTED.AWAITING_START]:
        BIMFileStatus.PENDING,
    [TaskStatusDetailNameSpace.NOT_STARTED.DEPENDENCY_BLOCKED]:
        BIMFileStatus.PENDING,
    [TaskStatusDetailNameSpace.IN_PROGRESS.PENDING_REVIEW]:
        BIMFileStatus.PENDING,
    [TaskStatusDetailNameSpace.IN_PROGRESS.HAS_ISSUES]: BIMFileStatus.REJECTED,
    [TaskStatusDetailNameSpace.COMPLETED.SUCCESSFULLY_COMPLETED]:
        BIMFileStatus.APPROVED,
    [TaskStatusDetailNameSpace.COMPLETED.WITH_ISSUES]:
        BIMFileStatus.APPROVED_WITH_ISSUES,
}

export class ProjectFile extends File {
    // public project: Project
    public type: ProjectFileTypeEnum
    public task: Task
    public project: string
    public originator: string
    public functionalBreakdown: string
    public spatialBreakdown: string
    public form: string
    public discipline: string
    public number: string
    public phase: string
    public bimFileStatus: BIMFileStatus

    constructor(data?: FileJsonInterface) {
        super(data)
        // this.project = new Project(data?.project) ?? new Project()
        this.type = data?.type ?? ProjectFileTypeEnum.OTHER
        this.task = new Task(data?.task) ?? new Task()
        const splittedName = this.name.split("-")
        this.project = splittedName[0] ?? ""
        this.originator = splittedName[1] ?? ""
        this.functionalBreakdown = splittedName[2] ?? ""
        this.spatialBreakdown = splittedName[3] ?? ""
        this.form = splittedName[4] ?? ""
        this.discipline = splittedName[5] ?? ""
        this.number = splittedName[6] ?? ""
        this.phase = splittedName[7] ?? ""
        this.bimFileStatus = taskStatusBimFileMapping[this.task.statusDetail]
    }

    get reviewers(): User[] {
        return this.task.reviews.map((review) => review.reviewer).flat()
    }

    get assignee(): User {
        return this.uploadedBy
    }

    get status(): BIMFileStatus {
        return taskStatusBimFileMapping[this.task.statusDetail]
    }

    public toJson(): FileJsonInterface {
        return {
            ...super.toJson(),
            type: this.type,
            task: this.task.toJson(),
            // project: this.project.toJson(),
        }
    }
}

export enum BIMFileNominationFields {
    PROJECT = "project",
    ORIGINATOR = "originator",
    FUNCTIONAL_BREAKDOWN = "functionalBreakdown",
    SPATIAL_BREAKDOWN = "spatialBreakdown",
    FORM = "form",
    DISCIPLINE = "discipline",
    NUMBER = "number",
}

export type ProjectBIMFileNominations = {
    [key in BIMFileNominationFields]: string[]
}
