import { useAppDispatch, useAppSelector } from "@/app/hooks"
import { ArticleStatusBar } from "@/components/Other/ArticleStatusBar"
import { Currency } from "@/components/Other/Currency"
import { Article } from "@/models/Article"
import { Budget } from "@/models/Finance"
import {
    MantineReactTable,
    MRT_ColumnDef,
    useMantineReactTable,
} from "mantine-react-table"
import { useEffect, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { getBudgetArticles, selectArticles } from "../../Articles/articlesSlice"
import { BudgetArticleTableActions } from "./BudgetArticleTableActions"

interface BudgetPerArticleTableProps {
    budget: Budget
}

export const BudgetPerArticleTable: React.FC<BudgetPerArticleTableProps> = ({
    budget,
}) => {
    const { t } = useTranslation()
    const articles = useAppSelector(selectArticles)
    const dispatch = useAppDispatch()
    const projectId = useParams<{ projectId: string }>().projectId ?? "-1"
    const budgetId = useParams<{ budgetId: string }>().budgetId ?? "-1"

    useEffect(() => {
        dispatch(getBudgetArticles({ projectId, budgetId }))
    }, [])

    const columns = useMemo<MRT_ColumnDef<Article>[]>(
        () => [
            {
                accessorKey: "name",
                header: t("article"),
            },
            {
                id: "description",
                accessorKey: "description",
                header: t("description"),
                size: 800,
            },
            {
                accessorKey: "unitPrice",
                header: t("unit_price"),
                Cell: ({ row }) => {
                    const article = row.original
                    return (
                        <span className="flex gap-1 items-center">
                            {article.unitPrice}{" "}
                            <Currency currency={budget.currency} />
                            {" / "}
                            {t(article.unit.toLowerCase())}
                        </span>
                    )
                },
            },
            {
                id: "engagedQuantity",
                header: t("engaged_quantity"),
                Cell: ({ row }) => {
                    const article = row.original
                    const engagedQuantity = (
                        parseInt(article.engagedPrice) / article.unitPrice
                    ).toFixed(2)
                    return (
                        <span>
                            {engagedQuantity} {t(article.unit.toLowerCase())}
                        </span>
                    )
                },
            },
            {
                id: "remainingQuantity",
                header: t("remaining_quantity"),
                Cell: ({ row }) => {
                    const article = row.original
                    const remainingQuantity = (
                        article.remainingPrice / article.unitPrice
                    ).toFixed(2)
                    return (
                        <span>
                            {remainingQuantity} {t(article.unit.toLowerCase())}
                        </span>
                    )
                },
            },
            {
                id: "totalQuantity",
                header: t("total_quantity"),
                Cell: ({ row }) => {
                    const article = row.original
                    const totalQuantity = (
                        parseInt(article.totalPrice) / article.unitPrice
                    ).toFixed(2)
                    return (
                        <span>
                            {totalQuantity} {t(article.unit.toLowerCase())}
                        </span>
                    )
                },
            },

            {
                accessorKey: "engagedPrice",
                header: t("engaged_price"),
                Cell: ({ row }) => {
                    const article = row.original
                    return (
                        <span className="flex items-center gap-1">
                            {parseInt(article.engagedPrice).toFixed(2)}{" "}
                            <Currency currency={budget.currency} />
                        </span>
                    )
                },
            },
            {
                accessorKey: "remainingPrice",
                header: t("remaining_price"),
                Cell: ({ row }) => {
                    const article = row.original
                    return (
                        <span className="flex items-center gap-1">
                            {article.remainingPrice.toFixed(2)}{" "}
                            <Currency currency={budget.currency} />
                        </span>
                    )
                },
            },
            {
                accessorKey: "totalPrice",
                header: t("total_price"),
                Cell: ({ row }) => {
                    const article = row.original
                    return (
                        <span className="flex items-center gap-1">
                            {parseInt(article.totalPrice).toFixed(2)}{" "}
                            <Currency currency={budget.currency} />
                        </span>
                    )
                },
            },
            {
                id: "marketQuantity",
                accessorKey: "marketQuantity",
                header: t("market_quantity"),
                Cell: ({ row }) => {
                    const article = row.original
                    return (
                        <span className="flex items-center gap-1">
                            {article.marketQuantity}{" "}
                            {t(article.unit.toLowerCase())}
                        </span>
                    )
                },
            },
            {
                id: "marketBudget",
                accessorKey: "marketBudget",
                header: t("market_budget"),
                Cell: ({ row }) => {
                    const article = row.original
                    return (
                        <span className="flex items-center gap-1">
                            {article.marketBudget.toFixed(2)}{" "}
                            <Currency currency={budget.currency} />
                        </span>
                    )
                },
            },
            {
                id: "status",
                accessorKey: "status",
                header: t("status"),
                Cell: ({ row }) => {
                    const article = row.original
                    return <ArticleStatusBar articleStatus={article.status} />
                },
            },
            {
                id: "items",
                header: t("items"),
                Cell: ({ row }) => {
                    const article = row.original
                    return <span>{article.ifcEntities.length}</span>
                },
            },
            {
                header: "",
                id: "actions",
                enableColumnActions: false,
                enableColumnFilters: false,
                enableColumnFilter: false,
                enableColumnOrdering: false,
                enableSorting: false,
                Cell: ({ row }) => {
                    return <BudgetArticleTableActions article={row.original} />
                },
            },
        ],
        [],
    )

    const table = useMantineReactTable<Article>({
        columns,
        data: articles,
        positionPagination: "top",
        initialState: {
            columnVisibility: {
                items: false,
                description: false,
                marketBudget: false,
                marketQuantity: false,
            },
        },
    })

    return <MantineReactTable table={table} />
}
