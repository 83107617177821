import { useAppDispatch, useAppSelector } from "@/app/hooks"
import { DropDown } from "@/components/Inputs/DropDown"
import { FileInput } from "@/components/Inputs/FileInput"
import { Input } from "@/components/Inputs/Input"
import { TextAreaInput } from "@/components/Inputs/TextAreaInput"
import { Form, SubmitType } from "@/features/Form/Form"
import { selectUserPermissions } from "@/features/User/userSlice"
import { Article, Unit } from "@/models/Article"
import { t } from "i18next"
import { FC, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { getBudgets, selectBudgets } from "../financeSlice"
import {
    clearErrors,
    createArticle,
    getBudgetArticles,
    updateArticle,
} from "./articlesSlice"

interface ArticleFormProps {
    article?: Article
    closeModal: () => void
}

export const ArticleForm: FC<ArticleFormProps> = ({ article, closeModal }) => {
    const [localArticle, setLocalArticle] = useState<Article>(
        article ? new Article(article.toJson()) : new Article(),
    )
    const [selectedBudget, setSelectedBudget] = useState<string>(
        article ? article.budget.id : "",
    )
    const [photo, setPhoto] = useState<File | null>(null)
    const errors = useAppSelector((state) => state.article.errors)
    const budgets = useAppSelector(selectBudgets)
    const dispatch = useAppDispatch()
    const projectId = useParams<{ projectId: string }>().projectId ?? "-1"
    const budgetId = useParams<{ budgetId: string }>().budgetId ?? "-1"
    const status = useAppSelector((state) => state.article.status)
    const userPermissions = useAppSelector(selectUserPermissions)

    const handleSubmit = async () => {
        const payload = localArticle.createArticlePayload(photo, selectedBudget)
        if (article) {
            const { type } = await dispatch(
                updateArticle({
                    projectId,
                    articleId: article.id,
                    articlePayload: payload,
                }),
            )
            if (type === updateArticle.fulfilled.type) {
                closeModal()
                dispatch(getBudgetArticles({ projectId, budgetId }))
            }
        } else {
            const { type } = await dispatch(
                createArticle({ projectId, articlePayload: payload }),
            )
            if (type === createArticle.fulfilled.type) {
                closeModal()
                dispatch(getBudgetArticles({ projectId, budgetId }))
            }
        }
    }
    const handleChange = (
        e:
            | React.ChangeEvent<HTMLInputElement>
            | React.ChangeEvent<HTMLTextAreaElement>,
    ) => {
        setLocalArticle(
            (prev) =>
                new Article({
                    ...prev.toJson(),
                    [e.target.name]: e.target.value,
                }),
        )
    }

    useEffect(() => {
        dispatch(getBudgets(projectId))
    }, [])

    return (
        <Form
            onSubmit={handleSubmit}
            statuses={[status.create, status.update]}
            loadingTitle={
                article
                    ? t("editing_article", { article: article.name })
                    : t("creating_article")
            }
            onCancel={closeModal}
            submitType={article ? SubmitType.Save : SubmitType.Create}
            clearErrors={clearErrors}
        >
            <h1 className="font-bold text-lg mb-5">
                {article
                    ? t("editing_article", { article: article.name })
                    : t("create_new_article")}
                :
            </h1>
            <Input
                name="name"
                type="text"
                placeholder="Concrete"
                label={t("name")}
                value={localArticle.name}
                onChange={handleChange}
                errors={errors.name}
                className="w-full"
            />
            <TextAreaInput
                name="description"
                label={t("description")}
                value={localArticle.description}
                onChange={handleChange}
                className="w-full"
                resizable={false}
            />
            <div className="flex items-center w-full gap-4 my-4">
                <Input
                    name="unitPrice"
                    type="number"
                    placeholder="Reinforced concrete with elevation..."
                    label={t("unit_price")}
                    value={localArticle.unitPrice}
                    onChange={handleChange}
                    className="!m-0 w-full"
                    errors={errors.unitPrice}
                />
                <div className="h-full w-full">
                    <DropDown
                        label={t("unit")}
                        name={t("unit")}
                        value={localArticle.unit}
                        onChange={(e) => {
                            setLocalArticle(
                                (prev) =>
                                    new Article({
                                        ...prev.toJson(),
                                        unit: e.target.value as Unit,
                                    }),
                            )
                        }}
                        options={Object.values(Unit).map((unit) => ({
                            value: unit,
                            label: t(unit.toLowerCase()) as string,
                        }))}
                        errors={errors.unit}
                    />
                </div>
            </div>
            <Input
                name="marketBudget"
                type="number"
                placeholder="1000"
                label={t("market_budget")}
                value={localArticle.marketBudget}
                onChange={handleChange}
                errors={errors.marketBudget}
                className="w-full"
            />
            <div className="flex items-center gap-1">
                <Input
                    name="marketQuantity"
                    type="number"
                    placeholder="100"
                    label={t("market_quantity")}
                    value={localArticle.marketQuantity}
                    onChange={handleChange}
                    errors={errors.marketQuantity}
                    className="w-full"
                />
                <p className="self-end">{t(localArticle.unit.toLowerCase())}</p>
            </div>

            <div className="my-4 w-full">
                <DropDown
                    label={t("budget")}
                    name={t("budget")}
                    value={selectedBudget}
                    onChange={(e) => {
                        setSelectedBudget(e.target.value)
                    }}
                    options={budgets.map((budget) => ({
                        value: budget.id,
                        label: budget.name,
                    }))}
                    errors={errors.budget}
                />
            </div>
            <FileInput
                label={t("photos")}
                setState={setPhoto as (file: File | File[] | null) => void}
                name={t("photos")}
                value={photo}
                image={true}
            />
        </Form>
    )
}
