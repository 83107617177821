import { useAppDispatch, useAppSelector } from "@/app/hooks"
import { SliceStatus } from "@/common/types"
import { Button } from "@/components/Inputs/Button"
import { DropDown } from "@/components/Inputs/DropDown"
import { HierarchyDropDown } from "@/components/Inputs/HierarchyDropDown"
import { Input } from "@/components/Inputs/Input"
import { Form, SubmitType } from "@/features/Form/Form"
import {
    Task,
    TaskDependenciesRelationType,
    TaskDependenciesType,
    TaskDependency,
} from "@/models/Task"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { t } from "i18next"
import {
    MRT_ColumnDef,
    MRT_Table,
    useMantineReactTable,
} from "mantine-react-table"
import { useEffect, useMemo, useState } from "react"
import { useParams } from "react-router-dom"
import { getPlanningTaskTree } from "../../Plannings/planningSlice"
import {
    getAllProjectTasksById,
    selectProjectTasks,
    selectProjectTasksTree,
} from "../../projectsSlice"
import {
    addTaskDependencies,
    clearErrors,
    getTaskDependencies,
    removeTaskDependencies,
    selectTaskDependencies,
} from "../tasksSlice"

interface TaskDependenciesFormProps {}

export const TaskDependenciesForm: React.FC<
    TaskDependenciesFormProps
> = ({}) => {
    const taskId = useParams<{ taskId: string }>().taskId ?? "-1"
    const projectId = useParams<{ projectId: string }>()?.projectId ?? ""
    const planningId = useParams<{ planningId: string }>()?.planningId ?? ""
    const dispatch = useAppDispatch()
    const taskDependencies = useAppSelector(selectTaskDependencies)
    const status = useAppSelector((state) => state.tasks.status)
    const taskTree = useAppSelector(selectProjectTasksTree)
    const tasks = useAppSelector(selectProjectTasks)
    const [selectedDependencyTask, setSelectedDependencyTask] = useState<{
        value: string
        label: string
    }>({
        value: "",
        label: "",
    })
    const [showAddDependencies, setShowAddDependencies] =
        useState<boolean>(false)
    const [newDependency, setNewDependency] = useState<TaskDependency>(
        new TaskDependency(),
    )
    const [dependenciesToAdd, setDependenciesToAdd] = useState<
        TaskDependency[]
    >([])
    const [dependenciesToRemove, setDependenciesToRemove] = useState<
        TaskDependency[]
    >([])
    const [localDependencies, setLocalDependencies] = useState<
        TaskDependency[]
    >([])
    const [isEditMode, setIsEditMode] = useState<boolean>(false)
    useEffect(() => {
        dispatch(getAllProjectTasksById(projectId))
    }, [])
    useEffect(() => {
        if (taskId) {
            setIsEditMode(true)
        }
    }, [taskId])
    useEffect(() => {
        if (isEditMode) {
            dispatch(getTaskDependencies({ projectId, taskId }))
        }
    }, [isEditMode])
    useEffect(() => {
        setLocalDependencies(taskDependencies)
    }, [taskDependencies])
    useEffect(() => {
        setNewDependency(
            new TaskDependency({
                ...newDependency.toJson(),
                dependencyTask:
                    tasks
                        .find(
                            (task) => task.id === selectedDependencyTask.value,
                        )
                        ?.toJson() ?? new Task().toJson(),
            }),
        )
    }, [selectedDependencyTask])
    const columns = useMemo<MRT_ColumnDef<TaskDependency>[]>(
        () => [
            {
                id: "task",
                accessorKey: "dependencyTask.name",
                header: t("dependency_task"),
            },
            {
                id: "dependencyType",
                header: t("dependency_type"),
                Cell: ({ row }) =>
                    t(row.original.dependencyType.toLocaleLowerCase()),
            },
            {
                id: "relationType",
                accessorKey: "relationType",
                header: t("relation_type"),
                Cell: ({ row }) => t(row.original.relationType.toLowerCase()),
            },
            {
                id: "lag",
                accessorKey: "lag",
                header: t("lag"),
                Cell: ({ row }) =>
                    `${row.original.lag} ${row.original.lag > 1 ? t("days") : t("day")}`,
            },
            {
                id: "actions",
                header: "",
                enableColumnActions: false,
                size: 10,
                Cell: ({ row }) => (
                    <div className="flex justify-end">
                        <FontAwesomeIcon
                            icon="times"
                            className="cursor-pointer text-red-400 transition hover:text-red-600 hover:scale-105"
                            onClick={() => {
                                setDependenciesToRemove((prev) => [
                                    ...prev,
                                    row.original,
                                ])
                            }}
                        />
                    </div>
                ),
            },
        ],
        [],
    )
    const localTable = useMantineReactTable<TaskDependency>({
        columns: columns,
        data: localDependencies,
        initialState: {
            density: "xs",
        },
    })
    const addedTable = useMantineReactTable<TaskDependency>({
        columns: columns,
        data: dependenciesToAdd,
        initialState: {
            density: "xs",
        },
    })
    const removedTable = useMantineReactTable<TaskDependency>({
        columns: columns,
        data: dependenciesToRemove,
        initialState: {
            density: "xs",
        },
    })
    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        if (dependenciesToAdd.length > 0) {
            dispatch(
                addTaskDependencies({
                    projectId,
                    taskId,
                    dependencies: dependenciesToAdd.map((dep) =>
                        dep.createPayload(),
                    ),
                }),
            ).then(() => {
                setDependenciesToAdd([])
                if (planningId) {
                    dispatch(getPlanningTaskTree({ planningId, projectId }))
                }
                dispatch(
                    getTaskDependencies({
                        projectId,
                        taskId,
                    }),
                )
            })
        }
        if (dependenciesToRemove.length > 0) {
            dispatch(
                removeTaskDependencies({
                    projectId,
                    taskId,
                    dependencies: dependenciesToRemove.map((dep) => dep.id),
                }),
            ).then(() => {
                setDependenciesToRemove([])
                if (planningId) {
                    dispatch(getPlanningTaskTree({ planningId, projectId }))
                }
                dispatch(
                    getTaskDependencies({
                        projectId,
                        taskId,
                    }),
                )
            })
        }
    }
    return (
        <div className="p-5">
            <Form
                loadingTitle={
                    isEditMode
                        ? status.read === SliceStatus.LOADING
                            ? t("loading_task_dependencies")
                            : t("updating_task_dependencies")
                        : t("creating_task_dependencies")
                }
                statuses={[status.update, status.read]}
                submitType={SubmitType.Save}
                clearErrors={clearErrors}
                onSubmit={handleSubmit}
                className="w-full"
            >
                <div className="flex justify-between items-center w-full mb-4">
                    <h1 className="text-2xl font-bold">{t("dependencies")}</h1>
                    <Button
                        name={t("add_predecessor")}
                        onClick={() =>
                            setShowAddDependencies(!showAddDependencies)
                        }
                        icon="plus"
                        type="button"
                    />
                </div>
                {showAddDependencies && (
                    <div className="w-full p-5 flex flex-col gap-4  border-y-2 border-primary-300">
                        <div className="flex gap-2 w-full items-center">
                            <p className="text-nowrap">
                                {t("dependency_task")}:
                            </p>
                            <HierarchyDropDown
                                className="w-full"
                                value={{
                                    value: newDependency.dependencyTask.id,
                                    label: `${newDependency.dependencyTask.wbs} - ${newDependency.dependencyTask.name}`,
                                }}
                                placeholder={t("select_dependency_task")}
                                onChange={(value) => {
                                    setSelectedDependencyTask(value)
                                }}
                                options={taskTree.map((task) => ({
                                    value: task.id,
                                    label: `${task.name}`,
                                    expanded: false,
                                    item: task,
                                }))}
                                name={t("parent_task")}
                            ></HierarchyDropDown>
                        </div>
                        <div>
                            <div className="flex gap-2 w-full items-center">
                                <p className="text-nowrap">
                                    {t("dependency_type")}:
                                </p>
                                <DropDown
                                    name={t("dependency_type")}
                                    onChange={(e) =>
                                        setNewDependency(
                                            new TaskDependency({
                                                ...newDependency.toJson(),
                                                dependencyType: e.target
                                                    .value as TaskDependenciesType,
                                            }),
                                        )
                                    }
                                    options={Object.keys(TaskDependenciesType)
                                        .filter(
                                            (type) =>
                                                type !==
                                                TaskDependenciesType.ERROR,
                                        )
                                        .map((type) => {
                                            return {
                                                label: t(type.toLowerCase()),
                                                value: type,
                                            }
                                        })}
                                    value={newDependency.dependencyType}
                                    disabled
                                />
                            </div>
                        </div>
                        <div>
                            <div className="flex gap-2 w-full items-center">
                                <p className="text-nowrap">
                                    {t("relation_type")}:
                                </p>
                                <DropDown
                                    name={t("relation_type")}
                                    onChange={(e) =>
                                        setNewDependency(
                                            new TaskDependency({
                                                ...newDependency.toJson(),
                                                relationType: e.target
                                                    .value as TaskDependenciesRelationType,
                                            }),
                                        )
                                    }
                                    options={Object.keys(
                                        TaskDependenciesRelationType,
                                    )
                                        .filter(
                                            (type) =>
                                                type !==
                                                TaskDependenciesRelationType.ERROR,
                                        )
                                        .map((type) => {
                                            return {
                                                label: t(type.toLowerCase()),
                                                value: type,
                                            }
                                        })}
                                    value={newDependency.relationType}
                                />
                            </div>
                        </div>
                        <div>
                            <div className="flex gap-2 w-full items-center">
                                <p className="text-nowrap">{t("lag")}:</p>
                                <Input
                                    name={t("lag")}
                                    className="flex items-center gap-2 !m-0"
                                    type="number"
                                    value={newDependency.lag}
                                    onChange={(e) =>
                                        setNewDependency(
                                            new TaskDependency({
                                                ...newDependency.toJson(),
                                                lag: parseInt(e.target.value),
                                            }),
                                        )
                                    }
                                />
                                <p>
                                    {newDependency.lag > 1
                                        ? t("days")
                                        : t("day")}
                                </p>
                            </div>
                        </div>
                        <Button
                            name={t("add")}
                            icon="plus"
                            type="button"
                            onClick={() => {
                                newDependency.dependencyType =
                                    TaskDependenciesType.PREDECESSOR
                                setDependenciesToAdd((prev) => [
                                    ...prev,
                                    newDependency,
                                ])
                            }}
                        />
                    </div>
                )}
                <div className="mt-4">
                    <MRT_Table table={localTable} />
                </div>
                {dependenciesToAdd.length > 0 && (
                    <div className="mt-4">
                        <h1 className="font-bold">{t("added_dependencies")}</h1>
                        <MRT_Table table={addedTable} />
                    </div>
                )}
                {dependenciesToRemove.length > 0 && (
                    <div className="mt-4">
                        <h1 className="font-bold">
                            {t("removed_dependencies")}
                        </h1>
                        <MRT_Table table={removedTable} />
                    </div>
                )}
            </Form>
        </div>
    )
}
