import { useAppDispatch, useAppSelector } from "@/app/hooks"
import { Button } from "@/components/Inputs/Button"
import { NewModal } from "@/components/Layouts/NewModal"
import { TagComponent } from "@/components/Other/TagComponent"
import { TaskDelayStatusBar } from "@/components/Other/TaskDelayStatus"
import { TaskStatusBar } from "@/components/Other/TaskStatusBar"
import { TaskStatusDetailBar } from "@/components/Other/TaskStatusDetailBar"
import { UsersShow } from "@/components/Other/UsersShow"
import { selectUserPermissions } from "@/features/User/userSlice"
import { MODULE_PERMISSIONS, TASK_PERMISSIONS } from "@/models/Permission"
import { TaskDelayStatus } from "@/models/Task"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { Outlet, useNavigate, useParams } from "react-router-dom"
import { TaskAskForReviewForm } from "./TaskForms/TaskAskForReviewForm"
import { TaskDeleteForm } from "./TaskForms/TaskDeleteForm"
import { TaskReviewForm } from "./TaskForms/TaskReviewForm"
import { TaskSendDelayedNotificationsForm } from "./TaskForms/TaskSendDelayedNotificationsForm"
import { getTaskById, getTaskTags, selectTask } from "./tasksSlice"

interface TaskDetailsProps {}

type taskAction =
    | "delete"
    | "edit"
    | "ask_for_review"
    | "review"
    | "unfollow"
    | "follow"
    | "send_delay_notifications"

export const TaskDetails: React.FC<TaskDetailsProps> = () => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const task = useAppSelector(selectTask)
    const taskId = useParams<{ taskId: string }>().taskId ?? "-1"
    const projectId = useParams<{ projectId: string }>()?.projectId ?? ""
    const [actions, setActions] = React.useState<taskAction[]>([])
    const [showDeleteModal, setShowDeleteModal] = React.useState(false)
    const [showEditModal, setShowEditModal] = React.useState(false)
    const [showResolveModal, setShowAskForReview] = React.useState(false)
    const [showReviewModal, setShowReviewModal] = React.useState(false)
    const [showUnfollowModal, setShowUnfollowModal] = React.useState(false)
    const [showFollowModal, setShowFollowModal] = React.useState(false)
    const [showSendDelayNotifications, setShowSendDelayNotifications] =
        React.useState(false)
    const navigate = useNavigate()
    const userPermissions = useAppSelector(selectUserPermissions)
    const status = useAppSelector((state) => state.tasks.status)
    const buttonMapping = {
        delete: (
            <Button
                name={t("delete")}
                key={0}
                type="button"
                onClick={() => setShowDeleteModal(true)}
                icon="trash"
                className="h-10 !bg-red-400 hover:!bg-red-600 !border-secondary-100 text-secondary-100"
            />
        ),
        edit: (
            <Button
                name={t("edit")}
                key={1}
                type="button"
                onClick={() => {
                    navigate(
                        `/dashboard/projects/${projectId}/tasks/${taskId}/edit/general`,
                    )
                    setShowEditModal(true)
                }}
                icon="gear"
                className="h-10 !bg-yellow-400 hover:!bg-yellow-600 !border-secondary-100 text-secondary-100"
            />
        ),
        ask_for_review: (
            <Button
                name={t("ask_for_review")}
                key={2}
                type="button"
                onClick={() => setShowAskForReview(true)}
                icon="check"
                className="h-10 !bg-green-400 hover:!bg-green-600 !border-secondary-100 text-secondary-100"
            />
        ),
        review: (
            <Button
                name={t("review")}
                key={3}
                type="button"
                onClick={() => setShowReviewModal(true)}
                icon="check"
                className="h-10 !bg-green-400 hover:!bg-green-600 !border-secondary-100 text-secondary-100"
            />
        ),
        unfollow: (
            <Button
                name={t("unfollow")}
                key={4}
                type="button"
                onClick={() => setShowUnfollowModal(true)}
                icon="star"
                className="h-10 !bg-yellow-400 hover:!bg-secondary-100 hover:!text-yellow-400 hover:!border-yellow-400 !border-secondary-100 text-secondary-100"
            />
        ),
        follow: (
            <Button
                name={t("follow")}
                key={5}
                type="button"
                onClick={() => setShowFollowModal(true)}
                icon="star"
                className="h-10 !bg-secondary-100 hover:!bg-yellow-400 hover:!border-secondary-100 hover:!text-secondary-100 !border-yellow-400 text-yellow-400"
            />
        ),
        send_delay_notifications: task.delayStatus !==
            TaskDelayStatus.ON_TIME && (
            <Button
                name={t("send_delay_notifications")}
                key={6}
                type="button"
                onClick={() => setShowSendDelayNotifications(true)}
                icon="bell"
                className="h-10 !bg-primary-100 hover:!bg-primary-200 hover:!border-secondary-100 hover:!text-secondary-100 !border-yellow-400 text-yellow-400"
            />
        ),
    }
    useEffect(() => {
        dispatch(getTaskById({ projectId, taskId }))
        dispatch(getTaskTags({ projectId, taskId }))
    }, [status.update])
    useEffect(() => {
        const actionMapping: {
            [key in TASK_PERMISSIONS]: taskAction[]
        } = {
            [TASK_PERMISSIONS.MANAGER]: [
                "edit",
                "delete",
                "send_delay_notifications",
            ],
            [TASK_PERMISSIONS.ASSIGNEE]: ["ask_for_review"],
            [TASK_PERMISSIONS.REVIEWER]: ["review"],
            [TASK_PERMISSIONS.FOLLOWER]: [],
        }
        const actions: taskAction[] = []
        userPermissions.permissions
            .filter(
                (permission) =>
                    permission.includes(task.id) ||
                    permission === MODULE_PERMISSIONS.All.All,
            )
            .forEach((permission) => {
                if (permission === MODULE_PERMISSIONS.All.All) {
                    actions.push(...actionMapping.manager)
                    return
                }
                const role = permission.split(".")[2] as TASK_PERMISSIONS
                actions.push(...(actionMapping[role] || []))
            })
        setActions([...new Set(actions)])
    }, [userPermissions])
    return (
        <div className="bg-secondary-100 p-4 shadow-lg rounded-lg min-h-[160px]">
            <div className="flex justify-between sm:flex-wrap">
                <div>
                    <div className="flex items-center gap-4  sm:flex-wrap  sm:mb-2">
                        <h3 className="text-2xl font-bold w-fit">
                            {task.name}
                        </h3>
                        <TaskStatusBar taskStatus={task.status} />
                        <TaskStatusDetailBar
                            taskStatusDetail={task.statusDetail}
                        />
                        <TaskDelayStatusBar
                            taskDelayStatus={task.delayStatus}
                        />
                    </div>
                    {task.parent && (
                        <div className="flex items-center gap-2">
                            <FontAwesomeIcon
                                icon="project-diagram"
                                className="text-primary-300"
                            />
                            <p className="!m-0 font-bold self text-primary-300">
                                {task.parent.name}
                            </p>
                        </div>
                    )}
                    <div className="flex gap-2 items-center mt-2">
                        {task.tags.map((tag) => (
                            <TagComponent tag={tag} key={tag.id} />
                        ))}
                    </div>
                    <h4 className="text-lg w-fit">{task.description}</h4>
                </div>
                <div className="flex gap-2 md:flex-col sm:flex-row sm:flex-wrap sm:mt-2">
                    {actions.map((action) => buttonMapping[action])}
                </div>
            </div>
            <div className="flex w-full justify-between mt-4 sm:flex-wrap sm:justify-start sm:gap-4">
                <div className="text-center">
                    <div className="flex items-center">
                        <FontAwesomeIcon icon="user" className="mr-2" />
                        <h4 className="text-lg font-bold">{t("created_by")}</h4>
                    </div>
                    <p>{task.createdBy.fullName}</p>
                </div>
                <div className="text-center">
                    <div className="flex items-center">
                        <FontAwesomeIcon
                            icon="bars-progress"
                            className="mr-2"
                        />
                        <h4 className="text-lg font-bold">{t("progress")}</h4>
                    </div>
                    <p>{task.progress}%</p>
                </div>
                <div className="text-center">
                    <div className="flex items-center">
                        <FontAwesomeIcon icon="calendar" className="mr-2" />
                        <h4 className="text-lg font-bold">{t("start_date")}</h4>
                    </div>
                    <p>
                        {t("intlDateTime", {
                            val: task.startDate,
                            formatParams: {
                                val: {
                                    weekday: "short",
                                    year: "numeric",
                                    month: "short",
                                    day: "numeric",
                                },
                            },
                        })}
                    </p>
                </div>
                <div className="text-center">
                    <div className="flex items-center">
                        <FontAwesomeIcon icon="calendar" className="mr-2" />
                        <h4 className="text-lg font-bold">{t("end_date")}</h4>
                    </div>
                    <p>
                        {t("intlDateTime", {
                            val: task.endDate,
                            formatParams: {
                                val: {
                                    weekday: "short",
                                    year: "numeric",
                                    month: "short",
                                    day: "numeric",
                                },
                            },
                        })}
                    </p>
                </div>
                <div className="text-center">
                    <div className="flex items-center">
                        <FontAwesomeIcon icon="user" className="mr-2" />
                        <h4 className="text-lg font-bold">{t("assignees")}</h4>
                    </div>
                    <div className="flex w-full justify-center items-center">
                        <UsersShow users={task.assignees} />
                    </div>
                </div>
                <div className="text-center">
                    <div className="flex items-center">
                        <FontAwesomeIcon icon="users" className="mr-2" />
                        <h4 className="text-lg font-bold">{t("reviewers")}</h4>
                    </div>
                    <div className="flex w-full justify-center items-center">
                        <UsersShow
                            users={task.reviews.map(
                                (review) => review.reviewer,
                            )}
                        />
                    </div>
                </div>
            </div>
            <NewModal
                title={t("review_task")}
                isShown={showReviewModal}
                closeModal={() => setShowReviewModal(false)}
            >
                <TaskReviewForm
                    task={task}
                    closeModal={() => setShowReviewModal(false)}
                />
            </NewModal>
            <NewModal
                title={t("ask_for_review")}
                isShown={showResolveModal}
                closeModal={() => setShowAskForReview(false)}
            >
                <TaskAskForReviewForm
                    task={task}
                    closeModal={() => setShowAskForReview(false)}
                />
            </NewModal>
            <NewModal
                title={t("edit_task")}
                isShown={showEditModal}
                closeModal={() => setShowEditModal(false)}
            >
                <Outlet />
            </NewModal>
            <NewModal
                title={t("delete_task")}
                isShown={showDeleteModal}
                closeModal={() => setShowDeleteModal(false)}
            >
                <TaskDeleteForm
                    task={task}
                    callback={() => {
                        setShowDeleteModal(false)
                        navigate(`/dashboard/projects/${projectId}/tasks`)
                    }}
                />
            </NewModal>
            <NewModal
                title={t("unfollow_task")}
                isShown={showUnfollowModal}
                closeModal={() => setShowUnfollowModal(false)}
            >
                <p>unfollow</p>
            </NewModal>
            <NewModal
                title={t("follow_task")}
                isShown={showFollowModal}
                closeModal={() => setShowFollowModal(false)}
            >
                <p>follow</p>
            </NewModal>
            <NewModal
                title={t("send_delay_notifications")}
                isShown={showSendDelayNotifications}
                closeModal={() => setShowSendDelayNotifications(false)}
            >
                <TaskSendDelayedNotificationsForm
                    eligible={task.eligibleForDelayNotifications}
                    closeModal={() => setShowSendDelayNotifications(false)}
                />
            </NewModal>
        </div>
    )
}
