import { useAppDispatch } from "@/app/hooks"
import { Button } from "@/components/Inputs/Button"
import { NewModal } from "@/components/Layouts/NewModal"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useLocation, useParams } from "react-router-dom"
import { FileExplorer } from "./FileExplorer"
import { FileManagerCreateForm } from "./Forms/FileManagerCreateForm"
import { getProjectRootFolders } from "./fileManagerSlice"

interface FileManagerProps {}

export const FileManager: React.FC<FileManagerProps> = () => {
    const [showCreateFolder, setShowCreateFolder] = useState<boolean>(false)
    const [showUploadFile, setShowUploadFile] = useState<boolean>(false)
    const [showMenu, setShowMenu] = useState<boolean>(false)

    const dispatch = useAppDispatch()
    const projectId = useParams<{ projectId: string }>().projectId ?? "-1"
    const folderId = useParams<{ folderId: string }>().folderId ?? "-1"
    const location = useLocation()

    const { t } = useTranslation()
    useEffect(() => {
        dispatch(getProjectRootFolders(projectId))
    }, [])

    return (
        <div className="flex flex-col h-full p-2 overflow-hidden">
            <div className="flex justify-between sm:flex-wrap sm:gap-3">
                <h1 className="text-4xl font-bold border-b-4 w-fit border-opacity-100 border-primary-300">
                    {t("files")}
                </h1>
                <FontAwesomeIcon
                    icon="list"
                    className={`text-xl transition hover:bg-primary-100 hover:text-secondary-100 p-2 rounded-full cursor-pointer ${
                        showMenu
                            ? `bg-primary-100 text-secondary-100`
                            : `bg-secondary-300 text-primary-300`
                    }`}
                    onClick={() => setShowMenu(!showMenu)}
                />
            </div>
            {showMenu && (
                <div className="bg-secondary-100 shadow-lg rounded-lg p-4 mt-3 flex justify-between sm:flex-wrap items-center sm:gap-y-2">
                    <div className="flex gap-4 sm:justify-between sm:w-full">
                        <Button
                            name={t("create_folder")}
                            icon="plus"
                            onClick={() => setShowCreateFolder(true)}
                        />
                        {!["file-manager", "bim-file-table"].includes(
                            location.pathname.split("/").pop() ?? "",
                        ) && (
                            <Button
                                name={t("upload_file")}
                                icon="file-upload"
                                onClick={() => setShowUploadFile(true)}
                            />
                        )}
                    </div>
                </div>
            )}
            <div className="h-full mt-3 flex flex-col overflow-hidden">
                <FileExplorer />
            </div>
            <NewModal
                title={t("create_folder")}
                isShown={showCreateFolder}
                closeModal={() => setShowCreateFolder(false)}
            >
                <FileManagerCreateForm
                    resourceType="folder"
                    parentFolderId={folderId ?? null}
                    closeModal={() => setShowCreateFolder(false)}
                />
            </NewModal>
            <NewModal
                title={t("upload_file")}
                isShown={showUploadFile}
                closeModal={() => setShowUploadFile(false)}
            >
                <FileManagerCreateForm
                    resourceType="file"
                    parentFolderId={folderId ?? null}
                    closeModal={() => setShowUploadFile(false)}
                    multiple={true}
                />
            </NewModal>
        </div>
    )
}
