import { library } from "@fortawesome/fontawesome-svg-core"
import { fab } from "@fortawesome/free-brands-svg-icons"
import { fas } from "@fortawesome/free-solid-svg-icons"
import { usePreferredLanguage } from "@uidotdev/usehooks"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { Outlet } from "react-router-dom"
import "./App.css"
import { SupportedLanguages } from "./app/common"
import { useAppDispatch, useAppSelector } from "./app/hooks"
import { Notifications } from "./features/AppNotification/AppNotifications"
import { selectNotifications } from "./features/AppNotification/AppNotificationsSlice"
import {
    initAppLanguage,
    setAppLanguage,
} from "./features/AppSettings/appSettingsSlice"
import "./i18n/config"

library.add(fas, fab)

import { pdfjs } from "react-pdf"

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    "pdfjs-dist/build/pdf.worker.min.mjs",
    import.meta.url,
).toString()
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`

function App() {
    const dispatch = useAppDispatch()
    const notifications = useAppSelector(selectNotifications)
    const zoneLanguage = usePreferredLanguage()
    const { i18n } = useTranslation()
    const appLanguage = useAppSelector((state) => state.appSettings.language)
    useEffect(() => {
        dispatch(initAppLanguage())
    }, [])
    useEffect(() => {
        if (appLanguage === "") {
            const language = zoneLanguage.split("-")[0]
            if (
                SupportedLanguages.map((language) => language.code).includes(
                    language,
                )
            ) {
                dispatch(setAppLanguage(language))
            }
        }
        i18n.changeLanguage(appLanguage)
        document.body.dir = i18n.dir()
    }, [appLanguage, i18n])

    return (
        <div
            className={`w-full h-full bg-primary-200 text-secondary-100 flex justify-center items-center overflow-hidden ${
                appLanguage === "ar"
                    ? "font-cairo font-semibold"
                    : "font-roboto"
            }`}
        >
            <Outlet />
            {notifications.length > 0 && (
                <Notifications notifications={notifications} />
            )}
        </div>
    )
}

export default App
