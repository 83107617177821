import { useAppDispatch, useAppSelector } from "@/app/hooks"
import { Input } from "@/components/Inputs/Input"
import { Form, SubmitType } from "@/features/Form/Form"
import { Planning } from "@/models/Planning"
import React from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { clearErrors, createPlanning, editPlanning } from "./planningSlice"

interface PlanningFormProps {
    closeModal: () => void
    planning?: Planning
}

export const PlanningForm: React.FC<PlanningFormProps> = ({
    closeModal,
    planning,
}) => {
    const [localPlanning, setLocalPlanning] = React.useState<Planning>(
        new Planning(planning?.toJson() ?? undefined),
    )
    const [editMode, setEditMode] = React.useState<boolean>(
        planning ? true : false,
    )
    const [planningCsv, setPlanningCsv] = React.useState<File | null>(null)
    const errors = useAppSelector((state) => state.plannings.errors)
    const dispatch = useAppDispatch()
    const projectId = useParams<{ projectId: string }>().projectId ?? "-1"
    const status = useAppSelector((state) => state.plannings.status)

    const { t } = useTranslation()
    const changePlanningAttribute = (key: string, value: any) => {
        setLocalPlanning(
            (prevState) =>
                new Planning({ ...prevState.toJson(), [key]: value }),
        )
    }
    const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        if (editMode) {
            const payload = localPlanning.updatePayload()
            const { type } = await dispatch(
                editPlanning({
                    projectId,
                    planningId: planning?.id,
                    updatePayload: payload,
                }),
            )
            if (type === editPlanning.fulfilled.type) closeModal()
            return
        } else {
            const payload = localPlanning.createPayloadWithCsv(planningCsv)
            const { type } = await dispatch(
                createPlanning({ createPlanningPayload: payload, projectId }),
            )
            if (type === createPlanning.fulfilled.type) closeModal()
        }
    }
    return (
        <Form
            onSubmit={onSubmit}
            statuses={[status.update, status.create]}
            loadingTitle={
                editMode
                    ? t("updating_planning")
                    : planningCsv
                      ? t("generating_planning_from_csv_notice")
                      : t("creating_planning_notice")
            }
            submitType={editMode ? SubmitType.Save : SubmitType.Create}
            clearErrors={clearErrors}
        >
            <div className="bg-secondary-100 min-w-[400px] p-5">
                <h1 className="text-xl font-bold">
                    {editMode
                        ? t("planning_edit_notice", {
                              planning: planning?.name,
                          })
                        : t("create_new_planning")}
                    :{" "}
                </h1>
                {
                    <>
                        <Input
                            label={t("name")}
                            value={localPlanning.name}
                            type="text"
                            name="planningName"
                            onChange={(e) =>
                                changePlanningAttribute("name", e.target.value)
                            }
                            errors={errors.name}
                        ></Input>
                        <Input
                            label={t("description")}
                            value={localPlanning.description}
                            type="text"
                            name="planningDescription"
                            onChange={(e) =>
                                changePlanningAttribute(
                                    "description",
                                    e.target.value,
                                )
                            }
                            errors={errors.description}
                        ></Input>
                        {/* {!editMode && (
                            <FileInput
                                className="mt-5"
                                label={t("planning_csv")}
                                name="planningCsv"
                                value={planningCsv}
                                setState={setPlanningCsv as any}
                            ></FileInput>
                        )} */}
                    </>
                }
            </div>
        </Form>
    )
}
