// import { createTask } from "../Plannings/Tasks/taskSlice"
import { TabbedWindow } from "@/components/Layouts/TabbedWindow"
import { IconProp } from "@fortawesome/fontawesome-svg-core"

interface TaskFormProps {
    tabs: {
        name: string
        route: string
        icon?: IconProp
    }[]
}

export const TaskForm: React.FC<TaskFormProps> = ({ tabs }) => {
    return (
        <div className="w-full ">
            <TabbedWindow tabs={tabs} />
        </div>
    )
}
