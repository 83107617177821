import { ResourcesIds } from "@/app/common"
import { useAppDispatch, useAppSelector } from "@/app/hooks"
import { Scrollable } from "@/components/Layouts/Scrollable"
import {
    getCurrentUserPermissionsInProject,
    selectUserPermissions,
} from "@/features/User/userSlice"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { t } from "i18next"
import React, { useEffect, useState } from "react"
import { useParams, useSearchParams } from "react-router-dom"
import { getProjectUsersById, selectProjectUsers } from "../projectsSlice"
import {
    getProjectGroups,
    selectProjectGroups,
} from "../UserManagement/userManagementSlice"
import { TaskReview as TaskReviewComponent } from "./TaskReview"
import {
    addTaskReviewsById,
    getTaskReviewsById,
    selectTask,
} from "./tasksSlice"

interface TaskReviewsProps {}

export const TaskReviews: React.FC<TaskReviewsProps> = ({}) => {
    const dispatch = useAppDispatch()
    const userPermissions = useAppSelector(selectUserPermissions)
    const task = useAppSelector(selectTask)
    const taskId = useParams<{ taskId: string }>().taskId ?? "-1"
    const projectId = useParams<{ projectId: string }>()?.projectId ?? ""
    const projectUsers = useAppSelector(selectProjectUsers)
    const projectGroups = useAppSelector(selectProjectGroups)
    const status = useAppSelector((state) => state.tasks.status)
    const userPermissionStatus = useAppSelector((state) => state.users.status)
    const [showModal, setShowModal] = useState<boolean>(false)
    const [reviewers, setReviewers] = useState<ResourcesIds>({
        users: [],
        groups: [],
    })
    const [queryParams] = useSearchParams()
    const [highlighted, setHighlighted] = useState<string>("")
    const containerRef = React.useRef<HTMLDivElement>(null)
    const highlightedRef = React.useRef<HTMLDivElement>(null)
    useEffect(() => {
        dispatch(getTaskReviewsById({ projectId, taskId }))
    }, [])
    useEffect(() => {
        dispatch(getProjectUsersById(projectId))
        dispatch(getProjectGroups(projectId))
    }, [])
    const addReviewers = () => {
        dispatch(
            addTaskReviewsById({
                projectId,
                taskId: taskId,
                reviewers: reviewers.users,
                groups: reviewers.groups,
            }),
        ).then(async () => {
            dispatch(getCurrentUserPermissionsInProject(projectId))
            setReviewers({ users: [], groups: [] })
            setShowModal(false)
        })
    }
    useEffect(() => {
        const reviewer = queryParams.get("reviewer")
        if (reviewer) {
            setHighlighted(reviewer)
            if (containerRef.current) {
                if (highlightedRef.current !== null) {
                    const { y: resourceY, height: resourceHeight } =
                        highlightedRef.current.getBoundingClientRect()
                    const { y: containerY, height: containerHeight } =
                        containerRef.current.getBoundingClientRect()
                    containerRef.current.scrollTo({
                        top:
                            resourceY -
                            containerY -
                            containerHeight / 2 +
                            resourceHeight / 2,
                        behavior: "smooth",
                    })
                }
            }
        }
    }, [highlightedRef.current])
    return (
        <div className="bg-secondary-100 shadow-lg rounded-lg p-4 w-full flex flex-col">
            <div className="flex items-center justify-between gap-2 lg:flex-wrap sm:flex-wrap">
                <h1 className="text-2xl font-bold text-nowrap">
                    {t("reviews")}
                </h1>
            </div>
            <div className="w-full mt-4">
                {task.reviews.length > 0 ? (
                    <Scrollable height="400px" innerRef={containerRef}>
                        <div className="flex flex-col gap-4">
                            {task.reviews.map((review) => (
                                <TaskReviewComponent
                                    review={review}
                                    key={review.id}
                                    highlighted={
                                        highlighted === review.reviewer.id
                                    }
                                    highlightedRef={
                                        highlighted === review.reviewer.id
                                            ? highlightedRef
                                            : null
                                    }
                                />
                            ))}
                        </div>
                    </Scrollable>
                ) : (
                    <div className="w-full h-full flex justify-center items-center opacity-20">
                        <h1 className="text-xl flex flex-col items-center">
                            <FontAwesomeIcon
                                icon="file-upload"
                                className="mr-2 text-6xl"
                            />
                            <p>{t("no_reviews_yet")}...</p>
                        </h1>
                    </div>
                )}
            </div>
        </div>
    )
}
